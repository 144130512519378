import React from "react"
import { Link, navigate } from 'gatsby'
import Layout from '../components/Layout'
import Seo from '../components/SEO'
import SignUpForm from '../components/Forms/SignUpForms/signUpForm'
//import AuthForm from '../components/Forms/SignUpForms/authSignUpForm'
import {
	Button,
	Container,
	//Message
} from 'semantic-ui-react'
import * as styles from './styles/pages.module.css'
import {
	//activateAccountColor,
	redirectColor,
	//backColor,
} from '../globalVar'

const Negosyo = require('../../lib/negosyo')

class SignUp extends React.Component {
	state = {
		password: '',
		contact: '',
		stage: 0,
		transition:false,//for state.stage transition
		proper:false,//to see if he went to authorization with proper sign up
	}

	componentDidMount() {

		Negosyo.isLoggedIn()
		.then((data)=>{
			//custom key is for when the navigate's target url has a backButton so the new page inherits the location.key for the site mobileHeader backButton
			if(data===true) {
				let customKey
				const {
					location
				} = this.props
				if(location.state) {
					if(location.state.customKey) {
						customKey=location.state.customKey
					}else {
						customKey=location.key
					}
				}else {
					customKey=location.key
				}
				navigate(
					`/my-account`,
					{
						replace:true,
						state:{
							customKey:customKey
						}
					}
				)
			}
		})//doesn't need error since isLoggedIn won't reject promise but will only resolve with true if logged in or false
	}

	//toggle between sign up and OTP confirmation
	toggleStage = ()=> {
		if(this.state.stage) {
			this.setState({
				stage: 0,
				transition:true,
			})
			setTimeout(()=>{
				this.setState({transition:false})

			}, 2000)
		} else {
			this.setState({
				stage: 1,
				transition:true,
				proper:false,
			})
			setTimeout(()=>{
				this.setState({transition:false})
			}, 2000)
		}
	}



	render() {
		/*
		const {
			toggleStage
		} = this
		*/
		const {
			location
		} = this.props
		const {
			//contact,
			stage,
			//password,//not sure if this should be reimplemented when/if authForm is used again, maybe better to just let the user input it again and just save name/contact
			//transition,
			//proper,
		} = this.state
		//add name to state so to remember name if user for some reason went back to sign up form from auth form(?)
		return (
			<Layout location={location}>
				<Seo title={"Sign Up"} meta={[{name: `robots`, content: `noindex`}]} />
				<Container className={styles.container}>
					<div className={styles.centerContent}>
						{ stage === 0 && (
							<React.Fragment>
								<SignUpForm
									location={location}
									loginType="Password"
									handleSubmit={(contact, password)=>{
										//custom key is for when the navigate's target url has a backButton so the new page inherits the location.key for the site mobileHeader backButton
										let customKey
										if(location.state) {
											if(location.state.customKey) {
												customKey=location.state.customKey
											}else {
												customKey=location.key
											}
										}else {
											customKey=location.key
										}
										navigate(
											"/my-account/",
											{
												replace:true,
												state: {
													customKey:customKey
												}
											}
										)
										this.setState({
											contact:contact,
											password:password,
											proper:true
										})
									}}
									shortenForm={true}

								/>
							</React.Fragment>
						)}
						<br/>
						<div className={styles.text}>
							Have an Account?
							<Button
								className={styles.mainButton}
								as={Link}
								color={redirectColor}
								to="/sign-in/"
							>
								Sign In
							</Button>
						</div>
					</div>
				</Container>
			</Layout>
		)
	}
}


export default SignUp


/*
{ stage === 0 && (
						<React.Fragment>
							<SignUpForm
								loginType="Password"
								handleSubmit={(contact, password)=>{
									this.setState({
										contact:contact,
										password:password,
										stage:1,
										proper:true
									})
								}}
							/>
							<div className={styles.text}>
								Haven't activated your account yet?
								<Button
									color={activateAccountColor}
									content="Activate Account"
									onClick={toggleStage}
									disabled={transition}
									className={styles.form}
								/>
							</div>

						</React.Fragment>

					)}
					{ stage === 1 && (
						<React.Fragment>
							<AuthForm
								contact={contact}
								password={password}
								proper={proper}
								handleSubmit={()=>{
									navigate("/my-account/")
								}}
								backFunc={toggleStage}
								backDisabled={transition}
							/>
						</React.Fragment>
					)}




*/
