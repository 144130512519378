import React from "react"
import * as styles from './styles/prompt.module.css'
import {
	Button,
	Modal
} from 'semantic-ui-react'
import {
	backColor,
	confirmationColor
} from '../../globalVar'

class Prompt extends React.Component {

	render() {
		const {
			open, //should be required
			closePrompt, //should be required
			content, //should be required
			cancelButtonProps,
			confirmButtonProps, //should be required because of the onClick
			modalProps
		} = this.props

		return (
			<React.Fragment>
				<Modal
					open={open}
					{...modalProps}
				>
					<Modal.Content className={content.className ? content.className:styles.msg}>
						{content.content}
					</Modal.Content>
					<Modal.Actions>
						{closePrompt && (
							<Button
								color={cancelButtonProps.color ? cancelButtonProps.color:backColor}
								content={cancelButtonProps.content ? cancelButtonProps.content:"Back"}
								onClick={closePrompt}
								className={cancelButtonProps.className ? cancelButtonProps.className:styles.form}
								name={cancelButtonProps.name ? cancelButtonProps.name:"promptCancelButton"}
							/>
						)}

						<Button
							content={confirmButtonProps.content ? confirmButtonProps.content:"Proceed"}
							color={confirmButtonProps.color ? confirmButtonProps.color:confirmationColor}
							onClick={confirmButtonProps.onClick}
							className={confirmButtonProps.className ? confirmButtonProps.className:styles.form}
							name={confirmButtonProps.name ? confirmButtonProps.name:"promptProceedButton"}
						/>
					</Modal.Actions>
				</Modal>

			</React.Fragment>
		)
	}
}

Prompt.defaultProps = {
	cancelButtonProps: {
		content:"Back",
		color:backColor,
		className:styles.form
	}
};

export default Prompt